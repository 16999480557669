import { Router } from "./config/router";
import { Provider } from "react-redux";
import store from "./redux/store";
import "./App.scss";
import "./assets/fonts/Pangram/stylesheet.css";
import { ConfigProvider } from "antd";
import { antdTheme } from "./data/constants";
import React, {useEffect, useState} from "react";
import {getCompanyData} from "./api/api";
import {addCompanyToSession} from "./actions/localstorage";
import {getMerchantId} from "./actions/commonFunctions";
import Spinner from '../src/components/common/loader'
import ReactGA from 'react-ga4';
import LogRocket from 'logrocket';
import ldclient from "./services/launchDarkly.service";
const currentURL = window.location.href;

let LOGROCKET_APP_ID = '';
if (currentURL.startsWith('https://ecommerce.curbwaste.com')) {
  LOGROCKET_APP_ID = "kjvoob/ecommerce-prod"
} else {
  LOGROCKET_APP_ID = process.env.REACT_APP_LOGROCKET_APP_ID || "kjvoob/ecommerce-non-prod"
}
LogRocket.init(LOGROCKET_APP_ID);

const measurementId = localStorage.getItem('measurementId');
if(measurementId) {
    ReactGA.initialize(measurementId);
}

function App() {
        const [companyData, setCompanyData] = useState();
        const isConditionMet = companyData && companyData?.theme && companyData?.theme?.favicon;
        const faviconPath = isConditionMet ? companyData.theme.favicon : "https://curbsidedriver-userfiles-mobilehub-1262477507.s3.amazonaws.com/company-logo/favicon.png"

        useEffect(() => {
            const searchParams = new URLSearchParams(window.location.search);
            const referrer = searchParams.get('referrer') || localStorage.getItem('referrer');
            const redirection = searchParams.get('redirection');
            const measurementId = searchParams.get('measurementId');
            const companyId = localStorage.getItem('referrer')
            if(companyId && (referrer !== companyId)) {
                localStorage.clear();
            }
            if(redirection) {
                localStorage.setItem('redirection', redirection)
            }
            if(measurementId) {
                localStorage.setItem('measurementId', measurementId)
            }
            if (referrer) {
                localStorage.setItem('referrer', referrer)
            } else {
                let referrer = localStorage.getItem('referrer');
                if (!referrer) window.location.replace('https://www.curbwaste.com')
            }
        }, []);

        useEffect(() => {
            companyData && companyData?.theme && companyData?.theme?.colors && companyData?.theme?.colors?.primary ?
                document.documentElement.style.setProperty('--primary-color', companyData.theme.colors.primary) :
                document.documentElement.style.setProperty('--primary-color', "#01aef0")

            companyData && companyData?.theme && companyData?.theme?.colors && companyData?.theme?.colors?.secondary ?
                document.documentElement.style.setProperty('--secondary-color', companyData.theme.colors.secondary) :
                document.documentElement.style.setProperty('--secondary-color', "#000000")

            companyData && companyData?.theme && companyData?.theme?.fonts && companyData?.theme?.fonts?.fontFamily ?
                document.documentElement.style.setProperty('--primary-font', companyData.theme.fonts.fontFamily) :
                document.documentElement.style.setProperty('--primary-font', "Pangram")
        }, [companyData])

        useEffect(() => {
            let link = document.querySelector("link[rel~='icon']");
            if (!link) {
                link = document.createElement('link');
                link.rel = 'icon';
                document.head.appendChild(link);
            }
            link.href = faviconPath;
        }, [isConditionMet, faviconPath])

        useEffect(() => {
            const measurementId = localStorage.getItem('measurementId');
            if(measurementId) {
                const script = document.createElement('script');
                script.src = `https://www.googletagmanager.com/gtag/js?id=${measurementId}`;
                script.async = true;
                script.innerHTML = `
                  window.dataLayer = window.dataLayer || [];
                function gtag(){dataLayer.push(arguments);}
                gtag('js', new Date());
                gtag('config', ${measurementId});
            `;
                document.head.appendChild(script);

                return () => {
                    document.head.removeChild(script);
                };
            }
        }, [measurementId]);

        useEffect(() => {
            ReactGA.send({ hitType: 'pageview', page: window.location.pathname });
        }, [])

        useEffect(() => {
            getData();
        }, []);

        const getData = async () => {
            await getCompanyData().then(async (response) => {
                // const curTitle = titleMap.find((item) => item.path === curLoc.pathname);
                // if (curTitle && curTitle.title) {
                //     document.title = response?.companyName ? curTitle.title + ` | ${response.companyName}` : curTitle.title;
                // }
                localStorage.setItem('companyData', JSON.stringify(response));
                await addCompanyToSession(response);
                setCompanyData(response)
                getMerchantId();
            }).catch((e) => {
                window.location.replace('https://www.curbwaste.com')
            });
        };

        const initLdClient = async (companyData) => {
            const companyId = localStorage.getItem('referrer');
            await ldclient.identify({
                key: companyId,
                name: companyData.companyName
              });
            await ldclient.waitUntilReady();
        }

        useEffect(() => {
            if (companyData) {
                initLdClient(companyData)
            };
        }, [companyData])

        return (
            <>
                {
                    !companyData ?
                        <Spinner appSpinner={'appSpinner'}/> :
                        <>
                            {
                                companyData?.theme?.customCSS ?
                                    <style dangerouslySetInnerHTML={{__html: companyData.theme.customCSS}}/> : null
                            }
                            <ConfigProvider theme={antdTheme}>
                                <Provider store={store}>
                                    <Router/>
                                </Provider>
                            </ConfigProvider>
                        </>
                }


            </>
        );
}

export default App;
