import * as LDClient from 'launchdarkly-js-client-sdk';

const currentURL = window.location.href;
let ldClientId = "";
if(currentURL.startsWith('https://dev-ecommerce.curbwaste.com')) {
  ldClientId = '637df88850a8aa10d0b9d9ca';
} else if(currentURL.startsWith('https://qa-ecommerce.curbwaste.com')) {
  ldClientId = '637df9c673f4f81190ffda03';
} else if(currentURL.startsWith('https://alpha-ecommerce.curbwaste.com')) {
  ldClientId = '637df9d3580eb511a3b535ce';
} else if(currentURL.startsWith('https://ecommerce.curbwaste.com')) {
  ldClientId = '637df88850a8aa10d0b9d9cb';
} else if(currentURL.startsWith('https://mycurbside.com')) {
  ldClientId = '637df88850a8aa10d0b9d9ca';
} else {
  ldClientId = process.env.REACT_APP_LAUNCH_DARKLY_CLIENT_ID;
}

const ldclient = LDClient.initialize(ldClientId, {
  key: 'test'
});

ldclient.setStreaming(true);
 
export default ldclient;
