import React, { useState } from "react";

//import components
import ProductThumb from "../../../assets/images/product-thumb.svg";
import { CaretDownIcon } from "../../svgicons";
import { isEcommerceEnhancementV2Enabled } from "../../../actions/localstorage";

const ProductCard = ({ select, data }) => {
  const [details, setDetails] = useState(false);

  const { product, pricingStructure, feeStructure, additionalFee, name, materialType
  } = data;

  const toggleDetails = () => setDetails((detail) => !detail);

  const companyData = JSON.parse(sessionStorage.getItem('companyData'));
  const productImages = companyData?.theme?.productImages || {};
  return (
    <div className="product-grid-item">
      <div className="product-card">
        <div className="product-card-row row p-0 m-0">
          <div className="product-card-col col-md-3 m-0">
            <div className={`product-thumb product-thumb-${product?.id}`}>
              <img src={productImages[product?.id] ? productImages[product?.id] : ProductThumb} alt="" />
            </div>
          </div>
          <div className="product-card-col col-md-9 justify-content-between">
            <div className="product-info">
              {isEcommerceEnhancementV2Enabled() ? (
                <div className="product-title">{name}</div>
              ) : (
                <div className="product-title">{product?.name}</div>
              )}
              <div className="product-desc">
                {feeStructure?.weight?.limitType === 'MINIMUM' &&
                  feeStructure.weight.weightMinimum != null && (
                    `Weight Minimum: ${feeStructure.weight.weightMinimum} Ton${feeStructure.weight.weightMinimum === 1 ? '' : 's'
                    }`
                  )}
                {feeStructure?.weight?.limitType === 'LIMIT' &&
                  feeStructure.weight.max != null && (
                    `Weight Allowance: ${feeStructure.weight.max} Ton${feeStructure.weight.max === 1 ? '' : 's'
                    }`
                  )}
              </div>
              <button onClick={toggleDetails} className="btn btn-more-info">
                Additional Details{" "}
                <CaretDownIcon
                  style={{ transform: details && "scaleY(-1)" }}
                  className="icon"
                />
              </button>
            </div>
            <div className="product-more-info col-md-3">
              <div className="product-price">${pricingStructure?.price}*</div>
              <button
                onClick={() => select(data)}
                className="btn btn-primary btn-select"
              >
                Select
              </button>
              <div className="product-fees-info">
                *Additional fees may apply
              </div>
            </div>
          </div>

          {/* <div className="product-card-col"></div> */}
        </div>
      </div>
      {details && (
        <div className="additional-details-card">
          <div className="row">
            <div className="col-md-5">
              <div className="additional-details-title">*Additional Fees</div>
              <ul className="additional-fees-list">
                {additionalFee.map((v, i) => {
                  return (
                    <li key={i}>
                      <span>{v.name}</span>
                      <span>
                        ${v?.charge?.value}{v.feePer && `/${v.feePer}`}
                      </span>
                    </li>
                  );
                })}
              </ul>
            </div>
            <div className="col-md-7">
              <div className="additional-details-title prohibited">
                Prohibited Items
              </div>
              <ul className="prohibited-items-list">
                {
                  companyData && companyData?.prohibitedItems && companyData?.prohibitedItems?.length > 0 ? 
                    companyData?.prohibitedItems.map((v, i) => (
                      <li key={i}>{v}</li>
                    )) : null
                }
                {/* {addtionalData?.prohibitedItems.map((v, i) => (
                  <li key={i}>{v}</li>
                ))} */}
              </ul>
            </div>
            {isEcommerceEnhancementV2Enabled() && (
              <div className="col-md-5">
                <span className="additional-details-title">
                  Material Type: <span style={{ fontWeight: '400' }}>{materialType?.name || '-'}</span>
                </span>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default ProductCard;
