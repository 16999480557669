import { SERVER_AXIOS } from "./config";

const GET_METHOD = async (apiUrl, header) =>
  await SERVER_AXIOS.get(apiUrl, header);

const getHeaders = () => ({
  companyId: localStorage.getItem("referrer"),
});

export const getSKUsBYZone = async (zipcode) => {
  try {
    const companyId = localStorage.getItem("referrer");
    const { data } = await GET_METHOD(`ecommerce/zones`, {
      params: {
        companyId: companyId,
        zipcode: String(zipcode),
      },
      headers: getHeaders(),
    });

    if (data?.zones) {
      return data.zones || [];
    } else {
      console.error(`Error: ${data?.message}`);
      return [];
    }
  } catch (error) {
    console.error("Error fetching SKUs:", error.message);
    return [];
  }
};
